<template>
  <div class="login-master-page">
    <div class="background">
      <div
        class="photo-render-outer"
        :style="`background-image: url('${backgroundImageUrl}')`"
        ref="image"
      >
        <div
          class="photo-render-inner"
          :style="`background-image: url('${backgroundImageUrl}')`"
          ref="image"
        >
          <slot />
        </div>
      </div>
    </div>
    <div class="content"><router-view /></div>
  </div>
</template>

<script>
import axios from "@/services/axios";
export default {
  name: "LoginMasterPage",
  data: function () {
    return {
      backgroundImageUrl: "",
    };
  },
  async mounted() {
    const loginBackgroundRequest = await axios.get("/api/loginbackground");
    this.backgroundImageUrl = loginBackgroundRequest.data.url;
  },
};
</script>

<style lang="scss" scoped>
.login-master-page {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  display: grid;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  .background {
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    margin: 1em;
    margin-right: 0;
    border-radius: 1em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
    @media screen and (max-width: 1024px) {
      display: none;
    }
    .photo-render-outer {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: -1;
      border-radius: 1em;

      .photo-render-inner {
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        backdrop-filter: blur(20px) brightness(0.6);
        border-radius: 1em;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    padding-bottom: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  grid-template-columns: 1fr 28em;
}
</style>
