<template>
  <div class="person" :style="`background-image: url('${image}')`" ref="image">
    <div>
      <p class="name">{{ person.name }}</p>
      <p class="photo-count" v-if="person.username">@{{ person.username }}</p>
      <p class="photo-count">{{ person.count }} photos</p>
    </div>
  </div>
</template>

<script>
import axios from "@/services/axios";

function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      loaded: false,
      image: "",
      observer: null,
    };
  },
  methods: {
    async render() {
      this.$store.dispatch("globalLoading/globalLoadingTracker", async () => {
        const image = await axios({
          responseType: "arraybuffer",
          url: `/api/photosembedded/people/${this.person.id}/premier`,
          method: "GET",
          headers: { Authorization: "Bearer " + this.$store.state.token },
          withCredentials: false,
        });
        this.image = `data:${
          image.headers["content-type"]
        };base64,${_arrayBufferToBase64(image.data)}`;
      });
    },
    intersectionChange(entry) {
      entry.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        this.render().then(() => {
          console.log("rendered");
        });
      });
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.intersectionChange, {
      root: this.$refs.image,
      // rootMargin: "6000px",
      threshold: 0.01,
    });
  },
  async mounted() {
    this.observer.observe(this.$refs.image);
    // await this.render();
  },
};
</script>

<style lang="scss" scoped>
.person {
  min-width: 8em;
  max-width: 8em;
  height: 8em;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  img {
    width: 8em;
    height: 8em;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.1em;
    height: 100%;
    opacity: 0;
    width: 100%;
    transition: 0.2s ease-in-out;
    border: 2px solid transparent;
    border-radius: 100%;
    &:hover {
      opacity: 1;
      transition: 0.2s ease-in-out;
      backdrop-filter: blur(2px) saturate(0.5) brightness(0.5);
      transform: scale(1.05);
      border: 2px solid #fff;
    }
    p {
      margin: 0px;
      &.name {
        font-weight: 600;
        padding: 0 0.3em;
      }
      &.photo-count {
        font-size: 0.7em;
      }
    }
  }
}
</style>
