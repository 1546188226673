import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faCamera,
  faCircleChevronRight,
  faCircleChevronLeft,
  faClock,
  faXmark,
  faDownload,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

library.add(faCamera);
library.add(faCircleChevronLeft);
library.add(faCircleChevronRight);
library.add(faClock);
library.add(faXmark);
library.add(faDownload);
library.add(faHeart);

const app = createApp(App);

app.use(store);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
