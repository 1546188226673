<template>
  <div>
    <h1>Recent Albums</h1>
    <div class="photo-row photos-group">
      <photo-render-background-div
        v-for="album of albums"
        class="photo-render"
        :key="album.id"
        :imageUrl="`/api/photos/album/${album.id}/cover`"
        @click="this.$router.push(`/photos/album/${album.id}`)"
      >
        <div>
          <h3>{{ album.name }}</h3>
          <span
            >{{ momentProcess(album.last_updated) }}<br />{{
              album.photo_count
            }}
            photos</span
          >
        </div>
      </photo-render-background-div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PhotoRenderBackgroundDiv from "@/components/PhotoRenderBackgroundDiv";

export default {
  components: {
    PhotoRenderBackgroundDiv,
  },
  computed: {
    albums() {
      return this.$store.state.albums;
    },
  },
  methods: {
    momentProcess(lastUpdated) {
      return moment(lastUpdated).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-row {
  display: flex;
  flex-direction: row;
  padding: 1em;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 1em;
  .photo-render {
    height: 13rem;
    width: 13rem;
    min-width: 13rem;
    max-width: 13rem;
    cursor: pointer;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.1em;
      height: 100%;
      opacity: 0;
      width: 100%;
      transition: 0.2s ease-in-out;
      border: 2px solid transparent;
      &:hover {
        opacity: 1;
        transition: 0.2s ease-in-out;
        backdrop-filter: blur(2px) saturate(0.5) brightness(0.5);
        transform: scale(1.05);
        border: 2px solid #fff;
      }
      p {
        margin: 0px;
        &.name {
          font-weight: 600;
          padding: 0 0.3em;
        }
        &.photo-count {
          font-size: 0.7em;
        }
      }
    }
  }
}
</style>
