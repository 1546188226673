<template>
  <div class="people-block-outer" ref="people">
    <div class="people-block" v-if="status === 'success'">
      <!-- <p>This may be a photo of</p> -->
      <div class="bubbles">
        <individual-person-bubble
          v-for="person of people"
          :key="person.id"
          :person="person"
          :approved="person.confirmed"
        />
      </div>
    </div>
    <div class="people-block" v-else-if="status === 'pending'">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import IndividualPersonBubble from "@/components/IndividualPersonBubble";
import axios from "@/services/axios";

export default {
  data: function () {
    return {
      people: [],
      observer: null,
      status: "pending",
    };
  },
  components: {
    IndividualPersonBubble,
  },
  props: {
    photo: String,
  },
  methods: {
    async render() {
      const group = await axios({
        url: `/api/photos/photo/${this.photo}/people`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      console.log(group.data);
      this.people = group.data;
      this.status = "success";
    },
    intersectionChange(entry) {
      entry.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        this.render().then(() => {
          console.log("rendered");
        });
      });
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.intersectionChange, {
      root: this.$refs.image,
      rootMargin: "0px",
      threshold: 0.0,
    });
  },
  mounted() {
    this.observer.observe(this.$refs.people);
  },
};
</script>

<style lang="scss" scoped>
.bubbles {
  display: grid;
  gap: 0.4em;
}
</style>
