import { createStore } from "vuex";
import axios from "@/services/axios";
import { globalLoadingStore } from "./globalloading";

export const store = createStore({
  state: {
    topLevelStatus: "awaiting-password", //awaiting-password, error, photos, loading
    token: "",
    rbacToken: "",
    password: "",
    days: {},
    people: {},
    top10: {},
    albums: {},
    camcorder: {},
    metadata: {},
    currentlyShowing: {
      box: false,
      group: null,
      image: null,
    },
    user: {},
    showHeader: false,
  },
  mutations: {
    updateTopLevelStatus(state, status) {
      state.topLevelStatus = status;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPassword(state, password) {
      state.password = password;
    },
    setPhotosAndPeople(state, object) {
      state.days = object.ph;
      state.people = object.pe;
      state.top10 = object.t1;
      state.albums = object.al;
      state.camcorder = object.cc;
      state.metadata = object.me;
    },
    setDays(state, days) {
      state.days = days;
    },
    setPeople(state, people) {
      state.people = people;
    },
    setTop10(state, top10) {
      state.top10 = top10;
    },
    setAlbums(state, albums) {
      state.albums = albums;
    },
    setCamcorder(state, camcorder) {
      state.camcorder = camcorder;
    },
    setCurrentlyShowing(state, object) {
      state.currentlyShowing = object;
    },
    setUser(state, user) {
      state.user = user;
    },
    rbacToken(state, token) {
      state.rbacToken = token;
    },
    setMetadata(state, metadata) {
      state.metadata = metadata;
    },
    showHeader(state, show) {
      state.showHeader = show;
    },
  },
  actions: {
    async updateMetadata({ commit, state }) {
      try {
        const metadata = await axios({
          url: `/api/photos/meta`,
          method: "GET",
          headers: { Authorization: "Bearer " + state.token },
        });
        commit("setMetadata", metadata.data);
      } catch (err) {
        console.err(err);
      }
    },
    async setToken({ commit, dispatch }, password) {
      commit("updateTopLevelStatus", "loading");
      try {
        const token = await axios({
          url: `/api/token`,
          method: "GET",
          headers: { Authorization: "Bearer " + password },
        });
        if (token.data.status === false) {
          commit("updateTopLevelStatus", "error");
          alert(token.data.message);
          return;
        }

        commit("updateTopLevelStatus", "loading");
        const metadata = await axios({
          url: `/api/photos/meta`,
          method: "GET",
          headers: { Authorization: "Bearer " + token.data.token },
        });
        commit("setMetadata", metadata.data);

        dispatch("globalLoading/globalLoadingTracker", async () => {
          const photos = await axios({
            url: `/api/photos/days`,
            method: "GET",
            headers: { Authorization: "Bearer " + token.data.token },
          });
          commit("setDays", photos.data);
        });

        dispatch("globalLoading/globalLoadingTracker", async () => {
          const people = await axios({
            url: `/api/photos/people`,
            method: "GET",
            headers: { Authorization: "Bearer " + token.data.token },
          });
          commit("setPeople", people.data);
        });

        // const top10 = await axios({
        //   url: `/api/photos/top10`,
        //   method: "GET",
        //   headers: { Authorization: "Bearer " + token.data.token },
        // });

        dispatch("globalLoading/globalLoadingTracker", async () => {
          const albums = await axios({
            url: `/api/photos/album`,
            method: "GET",
            headers: { Authorization: "Bearer " + token.data.token },
          });
          commit("setAlbums", albums.data);
        });

        // const camcorder = await axios({
        //   url: `/api/photos/camcorder/camcorder-init-load`,
        //   method: "GET",
        //   headers: { Authorization: "Bearer " + token.data.token },
        // });

        commit("setToken", token.data.token);
        // router.push("/photos"); // now happens in the loading component

        // commit("setPhotosAndPeople", {
        //   ph: photos.data,
        //   pe: people.data,
        //   // t1: top10.data,
        //   al: albums.data,
        //   cc: camcorder.data,
        //   me: metadata.data,
        // });
      } catch (err) {
        commit("updateTopLevelStatus", "awaiting-password");
      }
    },
    async signedInUser({ commit, dispatch }, { token, user, rbacToken }) {
      commit("updateTopLevelStatus", "loading");
      const metadata = await axios({
        url: `/api/photos/meta`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      commit("setMetadata", metadata.data);
      commit("setToken", token);
      commit("setUser", user);
      commit("rbacToken", rbacToken);

      dispatch("globalLoading/globalLoadingTracker", async () => {
        const photos = await axios({
          url: `/api/photos/days`,
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        });
        commit("setDays", photos.data);
      });

      dispatch("globalLoading/globalLoadingTracker", async () => {
        const people = await axios({
          url: `/api/photos/people`,
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        });
        commit("setPeople", people.data);
      });

      // const top10 = await axios({
      //   url: `/api/photos/top10`,
      //   method: "GET",
      //   headers: { Authorization: "Bearer " + token.data.token },
      // });

      dispatch("globalLoading/globalLoadingTracker", async () => {
        const albums = await axios({
          url: `/api/photos/album`,
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        });
        commit("setAlbums", albums.data);
      });
    },
  },
  getters: {
    personLrTag: (state) => (id) => {
      return state.people.find((tp) => tp.lrid.tag === id);
    },
    person: (state) => (id) => {
      return state.people.find((tp) => tp._id === id);
    },
    thisCamcorderDate: (state) => (year, day) => {
      return state.camcorder.allCamcorderEventsByDate.find(
        (cc) => cc._id === `${year}/${day}`
      );
    },
  },
});

store.registerModule("globalLoading", globalLoadingStore);
