<template>
  <div class="camcorder">
    <camcorder-dates-list />
    <router-view />
  </div>
</template>

<script>
import CamcorderDatesList from "@/components/Camcorder/CamcorderDatesList";

export default {
  components: { CamcorderDatesList },
  computed: {
    camcorderInitObject() {
      return this.$store.state.camcorder;
    },
  },
};
</script>

<style lang="scss">
.camcorder {
  display: grid;
  grid-template-columns: 16em 1fr;
  grid-template-rows: calc(100vh - var(--locked-header-height));
}
</style>
