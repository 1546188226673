<template>
  <div class="people-grid">
    <individual-in-people-grid
      v-for="person of people"
      :key="person.id"
      :person="person"
      @click="this.$router.push(`/photos/person/${person.id}`)"
    />
  </div>
</template>

<script>
import IndividualInPeopleGrid from "@/components/IndividualInPeopleGrid";
export default {
  components: {
    IndividualInPeopleGrid,
  },
  computed: {
    people() {
      return this.$store.state.people;
    },
  },
};
</script>

<style lang="scss" scoped>
.people-grid {
  display: flex;
  flex-direction: row;
  padding: 1em;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 1em;
}
</style>
