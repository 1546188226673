<template>
  <div class="camcorder-detail-view">
    <div class="timeline-wrap">
      <div class="timeline-scroll-group">
        <div class="timeline-top">
          <div class="time">00:00</div>
          <div class="time">01:00</div>
          <div class="time">02:00</div>
          <div class="time">03:00</div>
          <div class="time">04:00</div>
          <div class="time">05:00</div>
          <div class="time">06:00</div>
          <div class="time">07:00</div>
          <div class="time">08:00</div>
          <div class="time">09:00</div>
          <div class="time">10:00</div>
          <div class="time">11:00</div>
          <div class="time">12:00</div>
          <div class="time">13:00</div>
          <div class="time">14:00</div>
          <div class="time">15:00</div>
          <div class="time">16:00</div>
          <div class="time">17:00</div>
          <div class="time">18:00</div>
          <div class="time">19:00</div>
          <div class="time">20:00</div>
          <div class="time">21:00</div>
          <div class="time">22:00</div>
          <div class="time">23:00</div>
        </div>
        <div class="timeline-markers">
          <div
            class="marker"
            v-for="segment of thisCamcorderDate.segments"
            :key="segment._id"
            :style="`left: ${calculateSeconds(segment.datePhotographedUTC)}px`"
            @click="selectedVideo = segment._id"
          >
            <div class="tooltip">
              {{ tooltip(segment.datePhotographedUTC) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="camcorder-video-view">
      <video
        autoplay
        controls
        playsinline
        ref="videoElement"
        v-show="selectedVideo"
      >
        <source ref="videoSourceElement" src />
      </video>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data: function () {
    return {
      selectedVideo: null,
    };
  },
  computed: {
    thisCamcorderDate() {
      return this.$store.getters.thisCamcorderDate(
        this.$route.params.year,
        this.$route.params.day
      );
    },
  },
  methods: {
    calculateSeconds(segmentDateTime) {
      return (
        (moment(segmentDateTime).minutes() / 60) * 100 +
        moment(segmentDateTime).hours() * 100
      );
    },
    tooltip(segmentDateTime) {
      console.log(segmentDateTime);
      return moment(segmentDateTime).format("LTS");
    },
  },
  watch: {
    selectedVideo(newVideo) {
      console.log(this.$refs);
      this.$refs.videoElement.pause();
      this.$refs.videoSourceElement.setAttribute(
        "src",
        `/api/photosembedded/${newVideo}/smaller?token=${this.$store.state.token}`
      );
      this.$refs.videoElement.load();
      this.$refs.videoElement.play();
    },
  },
};
</script>

<style lang="scss">
.camcorder-detail-view {
  border: 1px solid #dddddd;
  border-radius: 0.25em;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  .camcorder-video-view {
    background-image: url("~@/assets/nosignal.gif");
    // filter: brightness(0.5);
    border-radius: 0.25em;
    height: calc((100vh - var(--locked-header-height)) / 20 * 19);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      height: 100%;
      width: 100%;
    }
  }
  .timeline-wrap {
    --height: calc((100vh - var(--locked-header-height)) / 20 - 2px);
    height: var(--height);
    width: calc(100vw - 16em - 2px);
    overflow-x: scroll;
    .timeline-scroll-group {
      height: var(--height);
      width: 2400px;
      container-type: inline-size;
      .timeline-top {
        height: var(--height);
        width: 2400px;
        position: absolute;
        z-index: -1;
        display: flex;
        align-items: center;
        .time {
          flex-grow: 1;
          border: 1px solid #dddddd;
        }
      }
      .timeline-markers {
        height: var(--height);
        width: 2400px;
        position: absolute;
        z-index: 6;
        .marker {
          position: absolute;
          height: 0px;
          width: 0px;
          background-color: #fff;
          border-radius: 100%;
          border: 5px solid #ffffff;

          &:hover .tooltip {
            visibility: visible;
          }
          .tooltip {
            visibility: hidden;
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 11;
            background-color: #151518;
            width: 8em;
            border: 1px solid #dddddd50;
          }
        }
      }
    }
  }
  //.timeline-wrap {
  //  height: calc((100vh - var(--locked-header-height)) / 20 - 1px);
  //  width: calc(100vw - 16em);
  //  overflow: scroll;
  //  .timeline-markers {
  //    height: 100%;
  //    width: 2400px;
  //    container-type: inline-size;
  //    white-space: pre;
  //    overflow-x: scroll;
  //    z-index: 2;
  //    position: relative;
  //    .marker {
  //      position: absolute;
  //      height: 10px;
  //      width: 10px;
  //      background-color: #fff;
  //      border-radius: 100%;
  //      top: 50%;
  //      &:hover .tooltip {
  //        visibility: visible;
  //      }
  //      .tooltip {
  //        visibility: hidden;
  //        position: absolute;
  //        top: 100%;
  //        left: 50%;
  //        z-index: 11;
  //        background-color: #151518;
  //      }
  //    }
  //  }
  //  .timeline-top {
  //    z-index: -1;
  //    position: absolute;
  //    height: 100%;
  //    width: 2400px;
  //    display: flex;
  //    justify-content: space-between;
  //  }
  //}
}
</style>
