<template>
  <div class="album" v-if="status">
    <div
      class="album-header"
      :style="`background-image:
      url('${getAlbumHeaderImageUrl}')`"
    >
      <div class="inner">
        <h1>{{ albumDetail.name }}</h1>
      </div>
    </div>
    <div class="photos-group">
      <template v-for="(photo, index) of loadedPhotoGroup" :key="photo.id">
        <photo-render-background-div
          class="photo"
          :imageUrl="`/api/photos/${photo.id}/thumb`"
          :style="span()"
          @click="(event) => showPhotoDetailOfPhoto(event, index)"
          loadType="lazy"
        >
          <p>
            {{ photographedDaysAgo(photo.date_utc) }}
          </p></photo-render-background-div
        >
      </template>
      <Teleport to="#photo-detail">
        <Transition>
          <photo-detail
            v-if="showPhotoDetail"
            v-model:show-photo-detail="showPhotoDetail"
            v-model:showing="showing"
            :showingGroupEndpoint="`/api/photos/album/${this.$route.params.album}/photos`"
          />
        </Transition>
      </Teleport>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "@/services/axios";

import PhotoRenderBackgroundDiv from "@/components/PhotoRenderBackgroundDiv";
import PhotoDetail from "@/components/PhotoDetail";

export default {
  components: {
    PhotoRenderBackgroundDiv,
    PhotoDetail,
  },
  data: function () {
    return {
      albumDetail: {},
      loadedPhotoGroup: [],
      status: false,
      showPhotoDetail: false,
      showing: null,
    };
  },
  methods: {
    span() {
      const random = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      if (random == 1) {
        return null;
      } else if (random == 2) {
        return `grid-column: span 2; grid-row: span 2`;
      } else if (random == 3) {
        return `grid-column: span 3; grid-row: span 3`;
      }
    },
    photographedDaysAgo(date) {
      return moment(date).fromNow();
    },
    dateToDate(date) {
      return moment(date).format("LL");
    },
    showPhotoDetailOfPhoto(event, index) {
      this.showing = index;
      this.showPhotoDetail = true;
    },
  },
  computed: {
    getAlbumHeaderImageUrl() {
      return `${process.env.VUE_APP_BACKEND}/api/photosEmbedded/album/${this.albumDetail.id}/lscover?token=${this.$store.state.token}`;
    },
  },
  async mounted() {
    try {
      const album = await axios({
        url: `/api/photos/album/${this.$route.params.album}/album`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      const photos = await axios({
        url: `/api/photos/album/${this.$route.params.album}/photos`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      console.log(album.data);
      this.albumDetail = album.data;
      this.loadedPhotoGroup = photos.data;
      this.status = true;
    } catch (err) {
      console.log(err);
    }
  },
  name: "AlbumView",
};
</script>

<style lang="scss" scoped>
.album-header {
  height: 40vh;
  text-align: center;

  background-size: cover;
  background-position: center;
  .inner {
    backdrop-filter: brightness(0.2);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
