<template>
  <div class="photo-detail-outer" v-if="render">
    <div class="photo-detail-inner" @keyup.left="nextPhoto()">
      <font-awesome-icon
        icon="fa-solid fa-xmark"
        class="close"
        @click="closePhoto"
      />
      <div
        class="image"
        v-for="(photo, index) of photoGroup"
        :key="photo.id"
        :id="index"
        :class="{
          before: index < localShowing,
          current: index == localShowing,
          after: index > localShowing,
        }"
      >
        <photo-render-background-div-background
          :imageUrl="`/api/photos/${photo.id}/small`"
          v-if="photo.type === 'still'"
        />

        <div class="details">
          <div class="bottom-nav">
            <p class="heading">Metadata</p>
            <metadata-v2 :photo="photo.id" />
            <p class="heading">People</p>
            <person-bubbles :photo="photo.id" />
            <p class="heading">Albums</p>
            <album-bubbles :photo="photo.id" />
            <p class="heading">Downloads</p>
            <div class="download-buttons">
              <button
                @click="
                  download(
                    `${apiUrl}/api/photos/${photo.id}/workableUnrotated`,
                    photo.id,
                    `universal`
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-download" />
                <div>
                  <p class="type">Universal</p>
                  <p class="subtext">Full-size, best quality</p>
                </div>
              </button>
              <button
                @click="
                  download(
                    `${apiUrl}/api/photos/${photo.id}/AdvancedDNG`,
                    photo.id,
                    `original`
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-download" />
                <div>
                  <p class="type">Digital Negative</p>
                  <p class="subtext">Advanced, not recommended</p>
                </div>
              </button>
              <button
                @click="
                  download(
                    `${apiUrl}/api/photos/${photo.id}/small`,
                    photo.id,
                    `small`
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-download" />
                <div>
                  <p class="type">Half</p>
                </div>
              </button>
              <button
                @click="
                  download(
                    `${apiUrl}/api/photos/${photo.id}/thumb`,
                    photo.id,
                    `bw`
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-download" />
                <div>
                  <p class="type">B&W</p>
                  <p class="subtext">Not recommended</p>
                </div>
              </button>
            </div>
            <button @click="prevPhoto" v-show="localShowing > 0">
              <font-awesome-icon icon="fa-solid fa-circle-chevron-left" />
            </button>
            <span>{{ index + 1 }} / {{ photoGroup.length }}</span>
            <button
              @click="nextPhoto"
              v-show="localShowing < photoGroup.length - 1"
            >
              <font-awesome-icon icon="fa-solid fa-circle-chevron-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <Teleport to="#download-progress">
      <Transition>
        <div class="download-progress" v-if="showDownloadProgress">
          <p>{{ downloadProgress }}%</p>
          <div class="progress-bar-wrap">
            <div
              class="progress-bar-inner"
              :style="`width: ${downloadProgress}%`"
            ></div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
  <div class="rendering" v-else>Rendering...</div>
</template>

<script>
import PhotoRenderBackgroundDivBackground from "./PhotoRenderBackgroundDivBackground.vue";
// import RenderExif from "@/components/RenderExif";
import axios from "@/services/axios";
import mime2ext from "mime2ext";
import FileDownload from "js-file-download";
// import VideoRenderDetail from "@/components/VideoRenderDetail";
import PersonBubbles from "@/components/PersonBubbles.vue";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async";
import AlbumBubbles from "./AlbumBubbles";
import MetadataV2 from "./MetadataV2";

var interval;

export default {
  data: function () {
    return {
      photoGroup: [],
      render: false,
      showDownloadProgress: false,
      downloadProgress: 0,
      reloadInterval: false,
    };
  },
  components: {
    PhotoRenderBackgroundDivBackground,
    // RenderExif,
    // VideoRenderDetail,
    PersonBubbles,
    AlbumBubbles,
    MetadataV2,
  },
  props: {
    showPhotoDetail: Boolean,
    showing: String,
    showingGroupEndpoint: String,
  },

  emits: ["update:showPhotoDetail", "update:showing"],
  computed: {
    apiUrl() {
      return process.env.VUE_APP_BACKEND;
    },
    localShowing: {
      get() {
        return this.showing;
      },
      set(to) {
        this.$emit("update:showing", to);
      },
    },
  },
  async mounted() {
    try {
      interval = setIntervalAsync(() => {
        this.loadPhotos();
      }, 1000);
    } catch (err) {
      alert(err);
    }
  },
  methods: {
    async loadPhotos() {
      try {
        const group = await axios({
          url: this.showingGroupEndpoint,
          method: "GET",
          headers: { Authorization: "Bearer " + this.$store.state.token },
        });
        this.photoGroup = group.data;
        this.render = true;
      } catch (err) {
        alert(`Error loading photos: ${err}`);
        throw new Error(err);
      }
    },
    nextPhoto() {
      if (this.localShowing < this.photoGroup.length - 1) {
        this.localShowing++;
      }
    },
    prevPhoto() {
      if (this.localShowing > 0) {
        this.localShowing--;
      }
    },
    closePhoto() {
      this.$emit("update:showPhotoDetail", false);
    },
    handleKey(event) {
      if (event.key === "ArrowRight") {
        this.nextPhoto();
      } else if (event.key === "ArrowLeft") {
        this.prevPhoto();
      } else if (event.key === "Escape") {
        this.closePhoto();
      }
    },
    handleDownloadProgressEvent(event) {
      if (event.progress != 1) {
        this.showDownloadProgress = true;
        this.downloadProgress = parseInt(event.progress * 100);
      } else {
        this.showDownloadProgress = false;
      }
    },
    async download(downloadUrl, photo, type) {
      try {
        const file = await axios({
          credentials: "omit",
          withCredentials: false,
          url: downloadUrl,
          method: "GET",
          headers: { Authorization: "Bearer " + this.$store.state.token },
          responseType: "blob",
          onDownloadProgress: this.handleDownloadProgressEvent,
        });
        FileDownload(
          file.data,
          `${photo}-${type}.${mime2ext(file.headers["content-type"])}`
        );
      } catch (err) {
        alert(`Error downloading photos: ${err}`);
        throw new Error(err);
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.handleKey);
  },
  async beforeUnmount() {
    await window.removeEventListener("keydown", this.handleKey);
    await clearIntervalAsync(interval);
  },
};
</script>

<style lang="scss">
.photo-detail-outer {
  position: fixed;
  z-index: 11;
  top: 0px;
  background-color: #0e0e0ed0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .photo-detail-inner {
    border-radius: 1em;
    height: calc(100vh - 4em);
    width: calc(100vw - 4em);
    overflow: auto;
    display: grid;
    grid-template-rows: 1fr;
    .image {
      height: calc(100vh - 4em);
      width: calc(100vw - 4em);
      grid-row: 1 / 1;
      display: grid;
      grid-template-columns: 1fr 340px;
      overflow: auto;
      @media screen and (max-width: 1080px) {
        display: inline;
        .photo-render-outer {
          height: 60vh;
        }
      }
      position: absolute;
      transition: left 0.5s;
      &.after {
        left: 100%;
      }
      &.current {
        left: 2em;
      }
      &.before {
        left: -100%;
      }
      .details {
        background-color: #1d1d21;
        text-align: left;
        padding: 2em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        user-select: none;
        p {
          margin: 0px;
        }
        .heading {
          text-transform: uppercase;
          opacity: 0.5;
          margin: 0.5em 0;
          font-weight: 700;
        }
        .download-buttons {
          display: grid;
          grid-template-rows: repeat(3, 1fr);
          gap: 0.4em;
          margin: 1em 0;
          button {
            text-align: left;
            border: 1.8px solid #fff;
            border-radius: 0.3em;
            background-color: #fff;
            color: #1d1d21;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.4em;
            padding: 0.4em;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
              0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
              0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
            svg {
              width: 2em;
              height: 1.4em;
            }
            div {
              display: flex;
              flex-direction: column;
              p {
                display: block;
                &.subtext {
                  font-size: 0.7em;
                }
              }
            }
          }
        }
        button {
          background-color: transparent;
          border: none;
          color: inherit;
          cursor: pointer;
        }
      }
    }
    .close {
      position: absolute;
      right: 2em;
      height: 1em;
      width: 1em;
      z-index: 20;
      padding: 0.5em;
      font-size: 1.2em;
      margin: 0px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.download-progress {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 13;
  background-color: #000000f0;
  flex-direction: column;
  .progress-bar-wrap {
    height: 1em;
    width: 100%;
    max-width: 6em;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.25em;
    .progress-bar-inner {
      background-color: #fff;
      height: 100%;
      width: 0%;
      border-radius: 0.25em;
      transition: width 0.2s ease-in-out;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
