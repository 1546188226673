<template>
  <div id="vote">
    <h4>
      Help to create the slideshow for Graduation by choosing your favourite
      photo out of the two shown below.
    </h4>
    <div class="photos" v-if="showPhotos">
      <div class="photo-render-selectable" @click="this.selection(photo1)">
        <photo-render-background-div-background
          :imageUrl="`/api/photos/${photo1}/small`"
        />
      </div>
      <div class="photo-render-selectable" @click="this.selection(photo2)">
        <photo-render-background-div-background
          :imageUrl="`/api/photos/${photo2}/small`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/services/axios";
import PhotoRenderBackgroundDivBackground from "../components/PhotoRenderBackgroundDivBackground.vue";
export default {
  components: { PhotoRenderBackgroundDivBackground },
  data() {
    return {
      photos: [],
      photo1: null,
      photo2: null,
      showPhotos: false,
    };
  },
  async mounted() {
    this.getPhotos();
  },
  methods: {
    async getPhotos() {
      try {
        this.showPhotos = false;
        const response = await axios.get(`/api/photos/voting/photos/random`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        });
        this.photo1 = response.data[0].photo1_id;
        this.photo2 = response.data[0].photo2_id;
        console.log(response.data);
        this.showPhotos = true;
      } catch (err) {
        alert(err);
      }
    },
    async selection(winner) {
      console.log(winner);
      await axios.post(
        `/api/photos/voting/result`,

        {
          winner: winner,
          photo1: this.photo1,
          photo2: this.photo2,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        }
      );
      this.getPhotos();
    },
  },
};
</script>

<style lang="scss">
#vote {
  height: calc(100vh - var(--locked-header-height));
  display: grid;
  grid-template-rows: 4rem 1fr;
  .photos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    .photo-render-selectable {
      border-radius: 4px;
      transition: 0.2s ease-in-out;

      &:hover {
        transition: 0.2s ease-in-out;
        border: 2px solid #fff;
        p {
          opacity: 1;
          transition: opacity 0.3s;
        }
      }
    }
  }
}
</style>
