<template>
  <div class="metadata" ref="people">
    <table v-if="status === 'success'">
      <tr>
        <td>
          <font-awesome-icon icon="fa-solid fa-clock" />
        </td>
        <td class="value">{{ dateToDate(metadata.timeShot.date_utc) }}</td>
      </tr>
      <tr v-if="metadata.exif.has_make_model">
        <td>
          <font-awesome-icon icon="fa-solid fa-camera" />
        </td>
        <td class="value">
          {{ metadata.exif.make }} {{ metadata.exif.model }}
          <span v-if="metadata.exif.has_lens_info"
            ><br />{{ metadata.exif.lens }}</span
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "@/services/axios";
import moment from "moment";

export default {
  data: function () {
    return {
      metadata: [],
      observer: null,
      status: "pending",
    };
  },
  props: {
    photo: String,
  },

  methods: {
    dateToDate(date) {
      return moment(date).format("dddd MMMM Do YYYY, h:mm:ss a");
    },
    async render() {
      const group = await axios({
        url: `/api/photos/photo/${this.photo}/metadata`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      console.log(group.data);
      this.metadata = group.data;
      this.status = "success";
    },
    intersectionChange(entry) {
      entry.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        this.render().then(() => {
          console.log("rendered");
        });
      });
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.intersectionChange, {
      root: this.$refs.image,
      rootMargin: "0px",
      threshold: 0.0,
    });
  },
  mounted() {
    this.observer.observe(this.$refs.people);
  },
};
</script>

<style lang="scss" scoped>
table {
  border-spacing: 1em;
  td.value {
    font-family: monospace;
  }
}
</style>
