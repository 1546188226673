<template>
  <div class="wrap">
    <!-- <loading-view /> -->

    <Transition name="loading">
      <loading-view key="loading" v-if="status === 'loading'" />
    </Transition>

    <header v-show="showHeader">
      <div class="left-text">
        <p class="title">Will's Perpetual Camera Roll</p>
        <p class="password-info" v-if="metadata.passwordName">
          {{ metadata.passwordName }}
        </p>
        <p class="password-info" v-else></p>
      </div>

      <div class="tabs" v-show="status === 'photos'">
        <router-link to="/photos/">Photos</router-link>
        <router-link to="/camcorder/">Videos</router-link>
        <router-link to="/vote/" v-if="metadata.passwordCanVote"
          >Vote</router-link
        >
        <router-link to="/admin/" v-if="isAdmin">Admin</router-link>
      </div>
      <a
        class="commit"
        tabindex="-1"
        :href="`https://github.com/willpakpoy/photos/commit/${commit}`"
        >c. {{ commit }}</a
      >
      <user-controls-right class="personContainer" />
    </header>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <!-- <div class="bottom">
      <p>A picture is worth a thousand words, but a memory is priceless.</p>
      <p>© Will Pak Poy 2023</p>
    </div> -->
    <div
      class="hang-tight-indicator"
      v-if="status === 'photos' && metadata.photosQueueCount.count > 0"
      v-show="metadata.photosQueueCount.count > 0"
    >
      <p>
        <strong>Hang tight. </strong>We're still processing
        {{ metadata.photosQueueCount.count }} photos. Check back soon.
      </p>
    </div>
  </div>
</template>

<script>
import LoadingView from "@/views/LoadingView";
import UserControlsRight from "@/components/UserControlsRight";
import { setIntervalAsync } from "set-interval-async";

export default {
  components: {
    LoadingView,
    UserControlsRight,
  },
  async mounted() {
    console.log(`Using API ${process.env.VUE_APP_BACKEND}`);
    try {
      setIntervalAsync(() => {
        if (this.$store.state.topLevelStatus === "photos") {
          this.$store.dispatch("updateMetadata");
        }
      }, 5000);
    } catch (err) {
      alert(err);
    }
  },
  computed: {
    status() {
      return this.$store.state.topLevelStatus;
    },

    metadata() {
      return this.$store.state.metadata;
    },
    commit() {
      return process.env.VUE_APP_GIT;
    },
    isAdmin() {
      if (this.$store.state.user.is_admin) {
        return true;
      } else return false;
    },
    showHeader() {
      return this.$store.state.showHeader;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/interdisplay/inter-display.css";

:root {
  --locked-header-height: 84px;
}

body,
html {
  margin: 0px;
  box-sizing: border-box;
  font-family: "InterDisplay", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #151518;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  height: 100%;
}

.wrap {
  min-height: 100%;
}

header {
  position: sticky;
  z-index: 10;
  top: 0px;
  background-color: #15151850;
  backdrop-filter: saturate(180%) blur(20px);
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: var(--locked-header-height);
  justify-items: baseline;
  align-items: center;
  .left-text {
    grid-column: 1;
    text-align: left;
    margin: 0;
    .title {
      font-weight: 700;
    }
    .password-info {
      // font-style: italic;
      opacity: 0.5;
    }
  }
  p {
    margin: 0px;
  }
  a.commit {
    position: fixed;
    top: 2px;
    right: 2px;
    color: #ffffff50;
    font-size: 0.5em;
  }
  .tabs {
    justify-self: center;
    background-color: #ffffff20;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
    padding: 0.5em;
    gap: 0.5em;
    display: flex;
    align-items: center;
    a {
      color: #fff;
      text-decoration: none;
      &.router-link-active {
        background-color: #fff;
        color: #000;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
          0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
          0 16px 16px rgba(0, 0, 0, 0.12);
        padding: 0.25em;
      }
    }
  }
  .personContainer {
    justify-self: flex-end;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.loading-enter-active,
.loading-leave-active {
  transition: transform 1s;
}

.loading-leave-to {
  transform: translateX(-100%);
}

.loading-enter-from {
  transform: translateX(100%);
}

.photos-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-flow: dense;
  gap: 1em;
  padding: 1em;

  div.photo-render {
    aspect-ratio: 1 / 1;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
    border: 2px solid #151518;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    p {
      opacity: 0;
      margin: 0px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      text-align: left;
      padding: 1em;
    }
    &:hover {
      transform: scale(1.01);
      transition: 0.2s ease-in-out;
      border: 2px solid #fff;
      p {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
}

.bottom {
  text-align: left;
  padding: 1.4em;
  background-color: rgba(255, 255, 255, 0.1);
  bottom: 0px;
  position: relative;
  p {
    margin: 0.5em 0;
  }
}

.hang-tight-indicator {
  position: fixed;
  bottom: 0;
  height: 4em;
  width: 100vw;
  text-align: left;
  padding: 1em;
  background-image: repeating-linear-gradient(
    120deg,
    #303030,
    #303030,
    20px,
    #3b3b3b 20px,
    #3b3b3b 40px
  );
  /* Expanded the size of the image to prevent visual blips when animation loop repeats */
  background-size: 5000%;
  animation: animatedBackground 800s linear infinite;
  display: flex;
  align-items: center;
  p {
    margin: 0px;
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -100% 0;
  }
}
</style>
