import { v4 as uuidv4 } from "uuid";

export const globalLoadingStore = {
  namespaced: true,
  state: {
    globalLoading: {},
    firstLoad: true,
  },
  mutations: {
    addNewGlobalLoadingItem(state, item) {
      state.globalLoading[item] = false;
    },
    completeGlobalLoadingItem(state, item) {
      state.globalLoading[item] = true;
    },
    setFirstLoad(state, status) {
      state.firstLoad = status;
    },
  },
  actions: {
    setGlobalLoading({ commit }, status) {
      commit("setGlobalLoading", status);
    },
    async globalLoadingTracker({ commit }, fn) {
      const itemUUID = await uuidv4();

      try {
        commit("addNewGlobalLoadingItem", itemUUID);
        await navigator.locks.request(
          "global-loading",
          { mode: "shared" },
          async () => {
            await fn();
          }
        );
      } catch (error) {
        console.error("Error while tracking global loading:", error);
      } finally {
        commit("completeGlobalLoadingItem", itemUUID);
      }
    },
  },
  getters: {
    percentageCompleted: (state) => {
      const completed = Object.values(state.globalLoading).filter(
        (item) => item === true
      ).length;
      const total = Object.values(state.globalLoading).length;
      return (completed / total) * 100;
    },
  },
};
