<template>
  <div class="password-wrap">
    <div v-if="mode === `initial`" class="password form2">
      <h2>What's the password?</h2>
      <input
        type="password"
        id="globalPassword"
        name="globalPassword"
        autocomplete="globalPassword"
        required
        autofocus
        v-model="globalPassword"
        placeholder="everybody's password"
        @keyup.enter="mode = `u/p`"
      />
      <button @click="mode = `u/p`">Continue</button>
    </div>
    <div v-else-if="mode === `u/p`" class="password form2">
      <h2>Choose a username and password</h2>
      <input
        type="text"
        id="username"
        name="username"
        autocomplete="username"
        required
        autofocus
        v-model="username"
        placeholder="username"
        @keyup.enter="genUser()"
      />
      <input
        type="password"
        id="globalPassword"
        name="globalPassword"
        autocomplete="globalPassword"
        required
        autofocus
        v-model="password"
        placeholder="your password"
        @keyup.enter="genUser()"
      />
      <button @click="genUser()">Sign up</button>
    </div>
    <div
      v-else-if="mode === `linkPeople`"
      class="password form2 peopleSelector"
    >
      <h2>Wait, who are you?</h2>
      <div
        class="person"
        v-for="person of people"
        :key="person._id"
        :style="`background-image: url('${process.env.VUE_APP_BACKEND}/api/photosEmbedded/people/${person._id}/premier?token=${this.$store.state.token}')`"
        @click="thisIsMe(person._id)"
        :class="{ user: person.user }"
      >
        <span v-if="person.user">@{{ person.user.username }}</span>
      </div>
      <button @click="skipped()">Skip</button>
    </div>
    <p>{{ errorMessages }}</p>
  </div>
</template>

<script>
import router from "@/router";
import axios from "@/services/axios";
export default {
  name: "SignInView",
  data: function () {
    return {
      mode: "initial",
      username: "",
      password: "",
      errorMessages: "",
      globalPassword: "",
      user: {},
      people: [],
    };
  },
  methods: {
    async genUser() {
      this.errorMessages = "";
      const genUserReq = await axios({
        url: `/api/auth/local/signup`,
        method: "post",
        credentials: true,
        data: {
          username: this.username,
          password: this.password,
          globalPassword: this.globalPassword,
        },
      });
      if (genUserReq.data.status === true) {
        const loginRequest = await axios({
          url: `/api/auth/local`,
          method: "post",
          credentials: true,
          data: {
            username: this.username,
            password: this.password,
          },
        });
        this.continuationToken = loginRequest.data.continuationToken;
        const globalLoginRequest = await axios({
          url: `/api/auth/continuation/setjwt`,
          method: "post",
          credentials: true,
          headers: {
            Authorization: `Bearer ${loginRequest.data.continuationToken}`,
          },
          data: {
            globalPassword: this.globalPassword,
          },
        });
        this.$store.dispatch("signedInUser", {
          token: globalLoginRequest.data.token,
          user: loginRequest.data.user,
        });
        this.loadPeople();
        this.mode = "linkPeople";
      } else {
        this.errorMessages = genUserReq.data.message;
      }
    },
    skipped() {
      router.push("/photos");
    },
    async thisIsMe(person) {
      const linkRequest = await axios({
        url: `/api/auth/continuation/signup/link`,
        method: "post",
        credentials: true,
        headers: { Authorization: "Bearer " + this.continuationToken },
        data: {
          person,
        },
      });
      console.log(linkRequest);
      router.push("/photos");
    },
    async loadPeople() {
      const people = await axios({
        url: `/api/photos/people`,
        method: "get",
        credentials: true,
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      this.people = people.data;
    },
  },
};
</script>

<style lang="scss">
.password-wrap {
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  .form2 {
    input {
      margin-bottom: 1em;
    }
    button {
      background-color: #fff;
      font-size: 18px;
      border-radius: 0.25em;
      border: 0px solid transparent;
      width: 100%;
      padding: 0.25em;
    }
  }
  .peopleSelector {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    width: 700px;
    gap: 1em;
    align-items: center;
    justify-content: center;
    > div {
      height: 100px;
      width: 100px;
      aspect-ratio: 1 / 1;
      background-size: cover;
      background-position: center;
      border-radius: 4px;
      transition: 0.2s ease-in-out;
      border: 2px solid #151518;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      &:hover {
        transform: scale(1.01);
        transition: 0.2s ease-in-out;
        border: 2px solid #fff;
      }
      &.user {
        pointer-events: none;
        cursor: not-allowed;
        span {
          height: 100%;
          width: 100%;
          backdrop-filter: blur(2px) saturate(0.5) brightness(0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8em;
        }
      }
    }
  }
}
</style>
