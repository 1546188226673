<template>
  <ul>
    <li
      v-for="date of camcorderInitObject.allCamcorderEventsByDate"
      :key="date._id"
    >
      <router-link :to="`/camcorder/${date._id}`">{{
        dateToDate(date.segments[0].datePhotographedUTC)
      }}</router-link>
    </li>
  </ul>
</template>

<script>
import moment from "moment/moment";

export default {
  computed: {
    camcorderInitObject() {
      return this.$store.state.camcorder;
    },
  },
  methods: {
    dateToDate(date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
  li {
    a {
      color: inherit;
      text-decoration: none;
      width: 100%;
      display: block;
      padding: 1em;
      &:hover {
        backdrop-filter: brightness(1.5);
      }
    }
  }
}
</style>
