<template>
  <div id="loading-wrap">
    <div class="inner-blue" :class="metadataClass">
      <TransitionGroup name="loading-metadata">
        <img
          src="@/assets/SVG/Rounded.svg"
          alt="loading"
          key="logo"
          :class="status"
        />

        <div
          class="right-box"
          v-if="metadata && metadataClass === 'display-metadata'"
          key="right-box"
        >
          <h1 class="metadata-heading">
            {{ metadata.passwordName }}
          </h1>
          <p>
            Loading
            {{
              metadata.passwordName
                ? Number(metadata.countPhotosAccessTo.count).toLocaleString()
                : ""
            }}
            photos...
          </p>

          <div class="progress-bar-wrap">
            <div
              class="progress-bar-inner"
              :style="`width: ${percentageCompleted}%`"
            ></div>
          </div>
          <p v-if="metadata.passwordCanVote">
            <strong>Bored?</strong> Vote for your favourite photos using the
            <em>Vote</em> tab on the top bar.
          </p>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("globalLoading", ["percentageCompleted"]),
    status() {
      return this.$store.state.topLevelStatus;
    },
    metadata() {
      return this.$store.state.metadata;
    },
  },
  data() {
    return {
      metadataClass: "loading-metadata",
      globalLoadingLock: null,
    };
  },
  methods: {
    async updateGlobalLoadingLock() {
      this.globalLoadingLock = await navigator.locks.query("global-loading");
      await delay(100);

      this.globalLoadingMaxNumber =
        this.globalLoadingMaxNumber < this.globalLoadingLock.held.count
          ? this.globalLoadingLock.held.count
          : this.globalLoadingMaxNumber;
      this.globalLoadingCurrentNumber = this.globalLoadingLock.held.count;
      await this.updateGlobalLoadingLock();
    },
  },

  async mounted() {
    await this.updateGlobalLoadingLock();
  },

  watch: {
    async metadata() {
      if (this.metadata) {
        await delay(1000);
        this.metadataClass = "display-metadata";
        this.$router.push("/photos");
        this.$store.commit("showHeader", true);
        await delay(2000);
        await navigator.locks.request(
          "global-loading",
          { mode: "exclusive" },
          async () => {
            await delay(500);
            this.$store.commit("globalLoading/setFirstLoad", false);

            this.$store.commit("updateTopLevelStatus", "photos");
          }
        );
      }
    },
  },
};
</script>

<style lang="scss">
#loading-wrap {
  background-color: #27aae190;

  z-index: 99999;
  position: fixed;
  width: 100vw;
  height: 100vh;

  .inner-blue {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    backdrop-filter: blur(20px) brightness(0.5);
    gap: 2.5rem;
    img {
      height: 12rem;
      width: 12rem;
      transition: all 1s ease-in-out;
      &.photos {
        transform: scale(10);
      }
    }
    h1 {
      text-align: left;
      margin: 0px;
      padding: 0;
      font-size: 3rem;
    }
    p {
      margin: 0.7rem 0;
    }
    .right-box {
      text-align: left;
      .progress-bar-wrap {
        height: 1em;
        width: 100%;
        max-width: 6em;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0.25em;
        .progress-bar-inner {
          background-color: #fff;
          height: 100%;
          width: 0%;
          border-radius: 0.25em;
          transition: width 0.2s ease-in-out;
        }
      }
    }
  }
}
.loading-metadata-enter-active,
.loading-metadata-leave-active {
  transition: all 1s ease-in-out;
}
.loading-metadata-enter-from,
.loading-metadata-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>
